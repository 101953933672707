<template>
	<div class="open-success-page">
		<div class="step-box">
			<div class="step-item">
				<van-image width="20" :src="resourceURL + 'images/step01_h.png'"/>
				<p>填写资料</p>
			</div>
		
			<div class="step-item">
				<img :src="resourceURL + 'images/step_line_h.png'" alt="">
			</div>
		
			<div class="step-item">
				<van-image width="20" :src="resourceURL + 'images/step02_h.png'"/>
				<p>身份验证</p>
			</div>
			<div class="step-item">
				<img :src="resourceURL + 'images/step_line_h.png'" alt="">
			</div>
			<div class="step-item">
				<van-image width="20" :src="resourceURL + 'images/step03_h.png'"/>
				<p>提交审核</p>
			</div>
		</div>
		
		<van-row class="content">
			<van-col span="24">
				<img :src="icon" alt="" class="icon-success">
			</van-col>
			<van-col span="24" class="notice-title">审核中</van-col>
			<van-col span="24" class="notice-desc">预审一般在1-2个工作日内完成，请耐心等待！</van-col>
		</van-row>
	</div>
</template>

<script>
	export default {
		name: 'account_success',
		data() {
			return {
				resourceURL: this.$axios.defaults.resourceURL,
				
				icon: this.$axios.defaults.resourceURL + "images/icon_open_user_success.png",

			}
		},
	}
</script>